html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: "Work Sans";
}

a {
  text-decoration: inherit;
  color: inherit;
}

.container {
  padding: 0 1rem;
  margin: 0 auto;
  max-width: 1024px;
}

