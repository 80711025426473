.wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #eee;
  background-color: white;

  position: fixed;
  top: 0;
  z-index: 2;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  max-width: 1400px;
}

.logoContainer {
  grid-column: 2;
  text-align: center;
}

.menuButtonContainer {
  display: flex;
  justify-content: flex-end;
  grid-column: 3;
}