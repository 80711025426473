.hero {
  padding: 4rem 0 6rem;
  border-bottom: 1px solid #ccc;

  .h {
    margin-top: 0;
    margin-bottom: .25em;
  }
}

.container {
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1rem;
  padding-top: 4rem;
}