.button {
  display: block;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  padding: 0;
  line-height: 0.6;
  text-align: center;
  border: none;
	outline: none;
	background-color: transparent;
	
	// making the dividers
	> span {
		// the second divider
		display: inline-block;
		position: relative;
		height: 2px;
		width: 2rem;
		border-radius: 1px;
		background: #293335;
		vertical-align: middle;
		
		// the first & the third dividers
		&:before, &:after {
		  display: inline-block;
			position: absolute;
			content: "";
			height: 2px;
			border-radius: 1px;
			background: #293335;
			// for the hover state
			transition: all 200ms;
		}
		&:before {
		  top: -.6em;
			left: .4em;
			width: 1.7em;
		}
		&:after {
		  top: .6em;
			left: .4em;
			width: 1.7em;
		}
	}
}