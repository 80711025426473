.heading, .h {
  margin-top: .25em;
  margin-bottom: 1em;
  font-family: "Playfair Display";
  font-weight: 600;
  font-size: 1.5rem;

  &--xxxl { font-size: 8rem; }
  &--xxl { font-size: 5rem; }
  &--xl { font-size: 3rem; }
  &--lg { font-size: 2rem; }
  &--sm { font-size: 1.2rem; } 
  &--xs { font-size: .9rem; }

  &--alt {
    font-family: "Work Sans";
    font-weight: 600;
  }
}

