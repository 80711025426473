.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.content {
  position: relative;
  z-index: 1;
  flex-grow: 1;
  background-color: white;
}